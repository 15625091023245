import React from "react";

const SubmitAssessmentListingIcon = () => {
  return (
    <svg
      width="30"
      height="34"
      viewBox="0 0 30 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.53846 10.5727H4.23077V23.735M25.7692 23.735V10.5727H21.4615M15 9.37605V1.35896M15 9.37605C13.8105 9.37605 12.8462 10.4475 12.8462 11.7692C12.8462 13.0909 13.8105 14.1624 15 14.1624C16.1895 14.1624 17.1538 13.0909 17.1538 11.7692C17.1538 10.4475 16.1895 9.37605 15 9.37605ZM29 32.1111H1L3.15385 27.3248H26.8462L29 32.1111ZM15 1L8.53846 12.0085C10.2615 14.1624 11.5538 16.9145 12.2 20.1453H17.8C18.4462 16.9145 19.7385 14.0427 21.4615 12.0085L15 1ZM11.7692 20.1453H18.2308V23.735H11.7692V20.1453Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SubmitAssessmentListingIcon;
