import CodeMirror from "@uiw/react-codemirror";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import "./CodeEditor.scss";
import TestCaseRightIcon from "../../../../utils/Images/TestCaseRightIcon";
import TestCaseWrongIcon from "../../../../utils/Images/TestCaseWrongIcon";
import { AssessmentButton } from "ui-components";
import { compileApplicantQuestion } from "../../../../service/SubmitAssessment/SubmitAssessment";
import Draggable from "react-draggable";
import StartCamera from "../StartCamera";
import { useTranslation } from "react-i18next";
interface CodeEditor {
  questionData: any;
  codingAnsTemplate: string;
  setCodingAnsTemplate: any;
  codeOutPut: any;
  testCases: any;
  setTestCases: any;
  setCodeOutput: any;
  timer: number;
  currentQuestionIndex: number;
  questionId: string;
  handleSubmitAnswer?: any;
  setDisableNext: any;
  setStartQuestionLoader: any;
  startQuestionLoader: boolean;
}
const CodeEditor: React.FC<CodeEditor> = ({
  questionData,
  codingAnsTemplate,
  setCodingAnsTemplate,
  codeOutPut,
  testCases,
  setTestCases,
  setCodeOutput,
  timer,
  currentQuestionIndex,
  questionId,
  handleSubmitAnswer,
  setDisableNext,
  setStartQuestionLoader,
  startQuestionLoader,
}) => {
  const { t } = useTranslation();
  const handleCompilerChange = (code: string) => {
    setDisableNext(false);
    setCodingAnsTemplate(code);
  };

  const handleRun = async () => {
    const runData = {
      questionId: questionData?.questionId,
      languageId: questionData?.metaData?.languageId,
      sourceCode: codingAnsTemplate,
    };
    const runRes = await compileApplicantQuestion(runData);
    if (runRes?.data?.status === 200) {
      setCodeOutput(
        runRes?.data?.customMessage?.testResults?.stdout
          ? runRes?.data?.customMessage?.testResults?.stdout
          : runRes?.data?.customMessage?.testResults?.stderr
            ? runRes?.data?.customMessage?.testResults?.stderr
                ?.replace(/\n/g, "")
                .replace(/(\/\/|{|})/g, "$1\n")
            : "",
      );
      setTestCases(runRes?.data?.customMessage?.testResults?.testCases);
    }
  };
  return (
    <>
      <Draggable bounds="parent" defaultPosition={{ x: 700, y: 150 }}>
        <div className="box">
          <StartCamera
            timer={timer}
            currentQuestionIndex={currentQuestionIndex}
            questionId={questionId}
            isCodingQuestion={true}
            data={questionData}
            handleSubmitAnswer={handleSubmitAnswer}
            setStartQuestionLoader={setStartQuestionLoader}
            startQuestionLoader={startQuestionLoader}
          />
        </div>
      </Draggable>
      <div className="runBtn">
        <AssessmentButton
          onClick={() => {
            handleRun();
          }}
        >
          {t("GENERAL.RUN")}
        </AssessmentButton>
      </div>
      <div className="parent">
        <div className="editor">
          <CodeMirror
            value={codingAnsTemplate}
            readOnly={setStartQuestionLoader}
            height="400px"
            theme={vscodeDark}
            onChange={(get) => {
              handleCompilerChange(get);
            }}
          />
        </div>
        <div className="transScriptSec">
          <div className="resultSec">
            {t("GENERAL.RESULT")}
            <div
              dangerouslySetInnerHTML={{ __html: codeOutPut }}
              className="resultContent"
            />
          </div>
          <div className="testcases">
            {testCases?.map((list: any, index: number) => {
              return (
                <div
                  key={index}
                  className="testcase"
                  style={{
                    border:
                      list?.result === "Success"
                        ? "1px solid #DFFFF7"
                        : "1px solid #FFC9C9",
                  }}
                >
                  <div className="testCaseTitle">
                    <span className="">test case</span>
                    <p>{list?.name}</p>
                  </div>
                  <div
                    style={{
                      background:
                        list?.result === "Success" ? "#DFFFF7" : "#FFC9C9",
                    }}
                    className="icon"
                  >
                    {list?.result === "Success" ? (
                      <TestCaseRightIcon />
                    ) : (
                      <TestCaseWrongIcon />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CodeEditor;
