interface MicroPhone {
  color?: string;
}
const MicroPhone: React.FC<MicroPhone> = ({ color }) => {
  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 9V11C15 14.866 11.866 18 8 18M8 18C4.13401 18 1 14.866 1 11V9M8 18V21M4 21H12M11 5H9M11 9H9M8 14C6.3431 14 5 12.6569 5 11V4C5 2.34315 6.3431 1 8 1C9.6569 1 11 2.34315 11 4V11C11 12.6569 9.6569 14 8 14Z"
        stroke={`${color ? color : "#5A5A5A"}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MicroPhone;
