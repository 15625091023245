import React, { ReactNode } from "react";
import HeaderLogo from "../../../utils/Images/HeaderLogo";
import "./SubmitAssessmentHeader.scss";
import { AssessmentButton } from "ui-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
interface ListingHeader {
  children?: ReactNode;
}
const SubmitAssessmentHeader: React.FC<ListingHeader> = ({ children }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  return (
    <div className="header container-fluid">
      <div className="logo-link">
        <HeaderLogo />
      </div>
      <div className="Btn">
        {children}
        <AssessmentButton
          className="lightBtn logoutBtn"
          onClick={() => {
            localStorage.removeItem("applicantToken");
            localStorage.removeItem("applicantId");
            navigate(`/${currentLanguage}/applicantLogin`);
          }}
        >
          {t("GENERAL.LOGOUT")}
        </AssessmentButton>
      </div>
    </div>
  );
};

export default SubmitAssessmentHeader;
