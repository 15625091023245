import { CheckboxRadio } from "ui-components";
interface MultipleType {
  data?: any;
  multipleTypeValue: any;
  setMultipleTypeValue: any;
  startQuestionLoader: boolean;
}
const MultipleType: React.FC<MultipleType> = ({
  data,
  multipleTypeValue,
  setMultipleTypeValue,
  startQuestionLoader,
}) => {
  const handleType = (item: any) => {
    if (multipleTypeValue.includes(item)) {
      // If the item is already selected, remove it from the selected items
      setMultipleTypeValue(
        multipleTypeValue.filter((selectedItem: any) => selectedItem !== item),
      );
    } else {
      // If the item is not selected, add it to the selected items
      setMultipleTypeValue([...multipleTypeValue, item]);
    }
  };
  return (
    <div>
      {data?.answerOptions?.map((item: any, ind: number) => (
        <div
          style={{
            pointerEvents: startQuestionLoader ? "none" : "auto",
          }}
          className={`${item?.option ? "d-flex gap-2 common" : "commonType"}`}
          onClick={() => handleType(item)}
          key={ind}
        >
          <CheckboxRadio
            name="singleType"
            type="checkbox" // Use type="checkbox" for multiple selection
            checked={multipleTypeValue.includes(item)}
            additionalProps={{ disabled: startQuestionLoader ? true : false }}
          />

          <div>
            {item?.option && <p>{item?.option}</p>}
            {item?.attachment && (
              <div className="ImageAttParent mb-2">
                <img
                  className="attachedImageQuestion"
                  src={item?.attachment}
                  alt="Attachment"
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MultipleType;
