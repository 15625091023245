import { handleError } from "../../utils/Common/Constant";
import apiClient from "../Connection/Connection";

// to get system preference
export const getFrontEndSystemPreference = async () => {
  try {
    return (await apiClient.get("systemPreference/getSystemPreference"))?.data
      ?.data;
  } catch (error) {
    handleError(error);
  }
};
