interface Camera {
  color?: string;
}
const Camera: React.FC<Camera> = ({ color }) => {
  return (
    <svg
      width="25"
      height="17"
      viewBox="0 0 25 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6111 6.15625V6C17.6111 3.23857 15.3227 1 12.5 1H6.11111C3.28832 1 1 3.23857 1 6V11C1 13.7614 3.28832 16 6.11111 16H12.5C15.3227 16 17.6111 13.7614 17.6111 11V10.8438M17.6111 6.15625L20.09 4.64C20.8777 4.15832 21.8707 4.13283 22.6832 4.57339C23.4958 5.01395 24 5.85119 24 6.76V10.24C24.0019 11.1497 23.4985 11.9886 22.6855 12.4304C21.8726 12.8723 20.8785 12.8471 20.09 12.365L17.6111 10.8438M17.6111 6.15625V10.8438M9.30555 11C7.89416 11 6.75 9.88075 6.75 8.5C6.75 7.11925 7.89416 6 9.30555 6C10.717 6 11.8611 7.11925 11.8611 8.5C11.8611 9.163 11.5919 9.79887 11.1126 10.2677C10.6333 10.7366 9.98329 11 9.30555 11Z"
        stroke={`${color ? color : "#5A5A5A"}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Camera;
