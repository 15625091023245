import { useCallback } from "react";

export const useSpeak = (voices: any[]) => {
  const speak = useCallback(
    (text: string, lang: string, onEndCallback: () => void) => {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = lang.toLowerCase();

      // Filter voices by language and gender (female first)
      let voice = voices.find(
        (v: any) =>
          v.lang.toLowerCase().includes(lang.toLowerCase()) &&
          v.name.toLowerCase().includes("female"),
      );

      // Filter voices by language and gender (female first, then male)
      if (!voice) {
        voice = voices.find(
          (v: any) =>
            v.lang.toLowerCase().includes(lang.toLowerCase()) &&
            v.name.toLowerCase().includes("male"),
        );
      }

      // Filter voices by language
      if (!voice) {
        voice = voices.find((v: any) =>
          v.lang.toLowerCase().includes(lang.toLowerCase()),
        );
      }

      // If neither female nor male voice found, select a default voice
      if (!voice) {
        voice = voices.find((v: any) => v.name === "Google UK English Female");
      }

      if (voice) {
        utterance.voice = voice;
      }

      // Add event listener for when the voice is over
      utterance.onend = () => {
        if (onEndCallback) {
          onEndCallback();
        }
      };

      window.speechSynthesis.speak(utterance);
    },
    [voices],
  );

  return speak;
};
