const CircleTick = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#35D4AE" fillOpacity="0.1" />
      <circle cx="16" cy="16" r="11" fill="#35D4AE" />
      <path
        d="M15.2686 19.989L15.2686 19.989L20.6805 13.2006C20.9781 12.8273 20.9761 12.2501 20.6576 11.8939C20.4931 11.7099 20.27 11.616 20.0411 11.6271C19.8132 11.6381 19.5991 11.7519 19.4454 11.9448L14.2101 18.5116L12.6341 16.8958C12.2738 16.5264 11.7226 16.5978 11.4245 17.0041C11.142 17.389 11.162 17.9659 11.4959 18.3082L13.2698 20.1269L13.4488 19.9523L13.2698 20.1269C13.8533 20.7251 14.7446 20.6463 15.2686 19.989Z"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default CircleTick;
