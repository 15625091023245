interface QuestionProgressBar {
  questionSet: number[];
  progressbar: number;
  subQuestionColors: string[];
}

const QuestionProgressBar: React.FC<QuestionProgressBar> = ({
  questionSet,
  progressbar,
  subQuestionColors,
}) => {
  return (
    <>
      <div className="parent">
        <div
          className="horizontalLinedisable"
          style={{ width: `${progressbar}%` }}
        ></div>
        <div className="horizontalLines"></div>
        {questionSet?.map((item, key) => (
          <div
            style={{
              border: `1px solid ${subQuestionColors[key]}`,
            }}
            className="questionProgress"
            key={key + item}
          >
            <div
              style={{
                backgroundColor: subQuestionColors[key],
              }}
              className="subQuestionProgress"
            ></div>
          </div>
        ))}
      </div>
    </>
  );
};

export default QuestionProgressBar;
