interface SubmitAssessmentCalender {
  width?: string;
  height?: string;
  classname?: string;
}
const SubmitAssessmentCalender: React.FC<SubmitAssessmentCalender> = ({
  width,
  height,
  classname,
}) => {
  return (
    <svg
      className={classname}
      width={width ? width : "37"}
      height={height ? height : "42"}
      viewBox="0 0 37 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 16.2C0 9.90394 5.07651 4.79999 11.3387 4.79999H25.6613C31.9234 4.79999 37 9.90393 37 16.2V30.6C37 36.896 31.9234 42 25.6613 42H11.3387C5.0765 42 0 36.8959 0 30.6V16.2ZM11.3387 8.39999C7.05405 8.39999 3.58065 11.8922 3.58065 16.2V30.6C3.58065 34.9077 7.05406 38.4 11.3387 38.4H25.6613C29.9459 38.4 33.4194 34.9077 33.4194 30.6V16.2C33.4194 11.8922 29.9459 8.39999 25.6613 8.39999H11.3387Z"
        fill="#35D4AE"
      />
      <path
        d="M21.4835 9C21.4835 9.99411 22.2851 10.8 23.2738 10.8C24.2625 10.8 25.0641 9.99411 25.0641 9H21.4835ZM25.0641 1.8C25.0641 0.805896 24.2625 0 23.2738 0C22.2851 0 21.4835 0.805896 21.4835 1.8H25.0641ZM11.9351 9C11.9351 9.99411 12.7367 10.8 13.7254 10.8C14.7142 10.8 15.5157 9.99411 15.5157 9H11.9351ZM15.5157 1.8C15.5157 0.805896 14.7142 0 13.7254 0C12.7367 0 11.9351 0.805896 11.9351 1.8H15.5157ZM25.6609 18C26.6496 18 27.4512 17.1941 27.4512 16.2C27.4512 15.2059 26.6496 14.4 25.6609 14.4V18ZM11.3383 14.4C10.3496 14.4 9.548 15.2059 9.548 16.2C9.548 17.1941 10.3496 18 11.3383 18V14.4ZM25.6609 25.2C26.6496 25.2 27.4512 24.3941 27.4512 23.4C27.4512 22.4059 26.6496 21.6 25.6609 21.6V25.2ZM11.3383 21.6C10.3496 21.6 9.548 22.4059 9.548 23.4C9.548 24.3941 10.3496 25.2 11.3383 25.2V21.6ZM18.4996 32.4C19.4883 32.4 20.2899 31.5941 20.2899 30.6C20.2899 29.6059 19.4883 28.8 18.4996 28.8V32.4ZM11.3383 28.8C10.3496 28.8 9.548 29.6059 9.548 30.6C9.548 31.5941 10.3496 32.4 11.3383 32.4V28.8ZM25.0641 9V1.8H21.4835V9H25.0641ZM15.5157 9V1.8H11.9351V9H15.5157ZM25.6609 14.4H11.3383V18H25.6609V14.4ZM25.6609 21.6H11.3383V25.2H25.6609V21.6ZM18.4996 28.8H11.3383V32.4H18.4996V28.8Z"
        fill="#35D4AE"
      />
    </svg>
  );
};

export default SubmitAssessmentCalender;
