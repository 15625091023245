import { useState, useEffect } from "react";

export const useVoices = () => {
  const [voices, setVoices] = useState<any[]>([]);

  useEffect(() => {
    const getVoices = () => {
      const voicess = window.speechSynthesis.getVoices();
      setVoices(voicess);
    };

    getVoices();
    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = getVoices;
    }
  }, []);

  return voices;
};
