import { useState } from "react";
import { useTranslation } from "react-i18next";
import { InputField } from "ui-components";
interface EssayType {
  data?: any;
  setEssayTypeValue: any;
  essayTypeValue: any;
  startQuestionLoader: boolean;
}
const characterLimit = 1000;

const EssayType: React.FC<EssayType> = ({
  setEssayTypeValue,
  essayTypeValue,
  startQuestionLoader,
}) => {
  const { t } = useTranslation();
  const [charCount, setCharCount] = useState(0);
  const handleInputChange = (id: any, value: any) => {
    setEssayTypeValue(value);
    setCharCount(value.length);
  };

  return (
    <div
      style={{
        pointerEvents: startQuestionLoader ? "none" : "auto",
      }}
      className="essayType"
    >
      <InputField
        additionalProps={{
          as: "textarea",
          rows: 12,
          style: { resize: "none" },
          // disabled: startQuestionLoader ? true : false,
        }}
        value={essayTypeValue}
        placeholder={t("GENERAL.PLACEHOLDER")}
        onChange={(e) => handleInputChange(null, e)}
      />
      <div
        className={
          essayTypeValue.length > characterLimit
            ? "EssayLimitAccessCssOver limitAccess"
            : "limitAccess"
        }
      >
        <p className={"charLimitMsg"}>
          {t("GENERAL.MUST_BE_LESS_THAN")} {characterLimit}
          {t("GENERAL.CHARACTERS")}
        </p>
        {charCount > 0 && (
          <p className="charLimit">
            {charCount <= characterLimit
              ? characterLimit - charCount
              : `-${charCount - characterLimit}`}
            &nbsp;
            {t("SUBMIT_ASSESSMENT.TEXT_EDITOR_LIMIT")}
          </p>
        )}
      </div>
    </div>
  );
};

export default EssayType;
