import { AssessmentButton, RangeSelector, showToast } from "ui-components";
import SubmitAssessmentHeader from "../../../components/SubmitAssessment/Header/SubmitAssessmentHeader";
import "./AssessmentDetails.scss";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import CustomModal from "../../../components/CustomModal/CustomModal";
import {
  getApplicantAssessmentStatusAndDetailsWithExperience,
  startApplicantAssessment,
  updateApplicantAssessmentSkillExperince,
} from "../../../service/SubmitAssessment/SubmitAssessment";
import { removeSessionStorageItem } from "../../../utils/SessionStorageMethod/removeSessionStorageItem";
import DeviceDetector from "../DeviceDetector/DeviceDetector";
import InstructionModal from "../InstructionModal/InstructionModal";
import { videoUploader } from "../../../utils/Common/MediaUploader";
import Footer from "../../../components/Footer/Footer";
import User from "../User/User";
import ButtonWrapper from "../CommonBtn/ButtonWrapper";
import SubmitAssessmentCalender from "../../../utils/Images/SubmitAssessmentCalender";
import SystemCompatibility from "../SystemCompatibility/SystemCompatibility";
import { systemPreferenceContext } from "../../../context/SystemPreference/SystemPreference";
import { useSearchParams } from "react-router-dom";
type MediaPermissions = {
  mic?: boolean | null;
  camera?: boolean | null;
};
const AssessmentDetails = () => {
  const loggedInParams = new URLSearchParams(window.location.search).get(
    "loggedIn",
  );
  const applicantIdInParams = new URLSearchParams(window.location.search).get(
    "appId",
  );
  const { assessmentIdParam } = useParams();
  const location = useLocation();
  const { preferenceData } = useContext(systemPreferenceContext);
  const [combinedInstructions, setCombinedInstructions] = useState<string>("");

  // Update combinedInstructions when preferenceData changes
  useEffect(() => {
    if (preferenceData?.submitAssessmentInstructions?.systemInstruction) {
      const instructions =
        preferenceData?.submitAssessmentInstructions?.systemInstruction || "";
      setCombinedInstructions(instructions);
    }
  }, [preferenceData?.submitAssessmentInstructions?.systemInstruction]);
  const { state } = location;
  const userEmail = state && state.userEmail;
  // remove timer value from session storage
  removeSessionStorageItem("timerValue");
  const [updateskills, setUpdateSkills] = useState([]);
  const [micPermissions, setMicPermissions] = useState<MediaPermissions>({
    mic: null,
  });
  const [cameraPermissions, setCameraPermissions] = useState<MediaPermissions>({
    camera: null,
  });
  const [internetSpeed, setInternetSpeed] = useState<boolean | number | null>(
    null,
  );
  const [internetSpeedCount, setInternetSpeedCount] = useState<number | null>(
    null,
  );
  const [capturedPic, setCapturedPic] = useState<boolean | null>(null);
  const [internetSpeedIsLoading, setInternetSpeedIsLoading] = useState(false);
  const [show, setShow] = useState(loggedInParams == "true" ? true : false);
  const [showCameraModal, setShowCameraModal] = useState(false);
  const [capturedImageData, setCapturedImageData] = useState("");
  const videoRef = useRef<HTMLVideoElement>(null);
  const capturedVideoRef = useRef<HTMLVideoElement>(null);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isUserSubmit, setIsUserSubmit] = useState(true);
  const { t, i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const [isCheckCamerapermission, setIsCheckCamerapermission] = useState(false);
  const [isCheckMicpermission, setIsCheckMicpermission] = useState(false);
  const [isCheckInternetpermission, setIsCheckInternetpermission] =
    useState(false);
  const [detailsData, setDetailsData] = useState<any>();
  // const [disableSkillsRange, setDisableSkillsRange] = useState(false);
  const [searchParams] = useSearchParams();
  const backgroundAPICall = searchParams.get("asc");
  // const [cameraLoader, setCameraLoader] = useState(false);
  useEffect(() => {
    if (applicantIdInParams && !backgroundAPICall) {
      getAssessmentDetailsWithExperience();
    }
  }, [applicantIdInParams]);
  const handleSkillSet = (id: number, value: number) => {
    setUpdateSkills((prevSkills: any) => {
      return prevSkills.map((skill: any) => {
        if (skill.skillId === id) {
          return { ...skill, experience: value };
        }
        return skill;
      });
    });
  };

  const checkInternetSpeed = async () => {
    setIsCheckInternetpermission(true);
    try {
      const numTests: number = 10; // Number of tests to perform
      const setInternetSpeedf = (speedInMbps: number | boolean) => {
        setInternetSpeedIsLoading(false);
        if (typeof speedInMbps === "number") {
          setInternetSpeed(true);
          setInternetSpeedCount(parseFloat(speedInMbps.toFixed(2)));
        } else {
          setInternetSpeed(false);
        }
      };

      const testSpeed = async () => {
        try {
          setInternetSpeedIsLoading(true);
          const imageApi: string = "https://picsum.photos/200/300";
          let totalBits: number = 0;

          for (let i = 0; i < numTests; i++) {
            const startTime = Date.now();
            await fetch(imageApi).then((response) => {
              const endTime = Date.now();
              const imageSize = parseInt(
                response.headers.get("content-length") || "0",
              );
              const timeDuration = (endTime - startTime) / 1000; // in seconds
              const loadedBits = imageSize * 8;
              const speedInBps = loadedBits / timeDuration; // bits per second
              totalBits += speedInBps;
            });
          }

          const averageSpeedInMbps =
            (totalBits / (numTests * 1024 * 1024)) * numTests; // Mbps
          setInternetSpeedf(averageSpeedInMbps);
          setInternetSpeed(true);
        } catch (error) {
          console.error("Error testing internet speed:", error);
          setInternetSpeed(false);
          setInternetSpeedIsLoading(false);
        }
      };

      // Initial function to start tests
      const init = async () => {
        await testSpeed();
      };
      init();
    } catch (error) {
      console.error("Error checking internet speed:", error);
      setInternetSpeed(false);
      setInternetSpeedIsLoading(false);
    }
  };

  const retestInternetConnect = () => {
    setInternetSpeedCount(null);
    setInternetSpeed(false);
    setInternetSpeed(null);
    checkInternetSpeed();
  };
  const checkMicPermissions = async (): Promise<void> => {
    setIsCheckMicpermission(true);
    // IMPORTANT CODE DONT DELETE
    // try {
    //   let micPermission = false;
    //   try {
    //     const audioStream = await navigator.mediaDevices.getUserMedia({
    //       audio: true,
    //     });
    //     micPermission = audioStream.getAudioTracks().length > 0;
    //     // Don't forget to stop the audio stream after checking permissions
    //     audioStream.getTracks().forEach((track) => track.stop());
    //   } catch (audioError) {
    //     console.warn("Error accessing audio:", audioError);
    //   }
    //   setMicPermissions({ mic: micPermission }); // Initialize camera permission as false
    // } catch (error) {
    //   // Permission denied or no media devices available
    //   console.warn("Error accessing media devices:", error);
    // }
  };

  const checkCameraPermission = async (): Promise<void> => {
    setIsCheckCamerapermission(true);
    // IMPORTANT CODE DONT DELETE
    // setCameraLoader(true);
    // setTimeout(() => {
    //   setCameraLoader(false);
    // }, 1000);
    // try {
    //   let cameraPermission = false;
    //   try {
    //     const videoStream = await navigator.mediaDevices.getUserMedia({
    //       video: true,
    //     });
    //     cameraPermission = videoStream.getVideoTracks().length > 0;
    //     if (cameraPermission) {
    //       if (videoRef.current) {
    //         videoRef.current.srcObject = videoStream;
    //       }
    //     }
    //   } catch (videoError) {
    //     console.warn("Error accessing video:", videoError);
    //   }
    //   // setCameraPermissions({ camera: cameraPermission });
    // } catch (error) {
    //   console.warn("Error accessing media devices:", error);
    // }
  };

  const captureImageCallBack = () => {
    checkCameraPermission();
    startCameraForImage();
    setShowCameraModal(true);
  };

  // start video capture to take image
  const startCameraForImage = async (): Promise<void> => {
    try {
      let cameraPermission = false;
      try {
        const videoStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        cameraPermission = videoStream.getVideoTracks().length > 0;
        if (cameraPermission) {
          if (capturedVideoRef.current) {
            capturedVideoRef.current.srcObject = videoStream;
          }
        }
      } catch (videoError) {
        console.warn("Error accessing video:", videoError);
      }
    } catch (error) {
      console.warn("Error accessing media devices:", error);
    }
  };

  // take image
  const handleCapture = async () => {
    if (capturedVideoRef.current) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      if (context) {
        const videoWidth = capturedVideoRef.current.videoWidth;
        const videoHeight = capturedVideoRef.current.videoHeight;

        canvas.width = videoWidth;
        canvas.height = videoHeight;

        context.drawImage(
          capturedVideoRef.current,
          0,
          0,
          videoWidth,
          videoHeight,
          0,
          0,
          canvas.width,
          canvas.height,
        );
        canvas.toBlob(async (blob) => {
          if (blob) {
            const imageUrl = await videoUploader(
              blob,
              `${detailsData?.mediaPath}${applicantIdInParams}`,
              "png",
            );
            setCapturedImageData(imageUrl);
          }
        }, "image/png");
        const capturedDataURL: any = canvas.toDataURL("image/png");
        if (capturedDataURL.length > 6) {
          setCapturedImageData(capturedDataURL);
          setCapturedPic(true);
        } else {
          setCapturedPic(false);
        }
      }
    }
  };

  // close camera after taking image
  const closeCameraCaptureModal = () => {
    if (capturedVideoRef.current && capturedVideoRef.current.srcObject) {
      const mediaStream: MediaStream = capturedVideoRef.current
        .srcObject as MediaStream;
      mediaStream
        .getTracks()
        .forEach((track: MediaStreamTrack) => track.stop());
    }
  };

  const isBtnEnable = (
    micPermissions: any,
    camera: any,
    internetSpeed: any,
    capturedPic: any,
  ) => {
    if (
      micPermissions?.mic !== true ||
      camera?.camera !== true ||
      !internetSpeed ||
      capturedPic !== true
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const updateSkillSet = new Set<string>();
    let updateSkill = [];
    if (detailsData?.data?.length > 0) {
      updateSkill = detailsData?.data.reduce((uniqueSkills: any, item: any) => {
        // Check if the item's skillName is already in the updateSkillSet
        if (!updateSkillSet.has(item.skillName)) {
          // If not found, add the skillName to the updateSkillSet and push the item to uniqueSkills
          updateSkillSet.add(item.skillName);
          uniqueSkills.push({ ...item });
        }
        return uniqueSkills;
      }, [] as any[]);
      // setDisableSkillsRange(true);
    } else {
      updateSkill = detailsData?.assessmentSkills.reduce(
        (uniqueSkills: any, item: any) => {
          // Check if the item's skillName is already in the updateSkillSet
          if (!updateSkillSet.has(item.skillName)) {
            // If not found, add the skillName to the updateSkillSet and push the item to uniqueSkills
            updateSkillSet.add(item.skillName);
            uniqueSkills.push({ ...item, experience: 0 });
          }
          return uniqueSkills;
        },
        [] as any[],
      );
      // setDisableSkillsRange(false);
    }

    setUpdateSkills(updateSkill);
  }, [detailsData?.assessmentSkills]);

  // call startApplicantAssessment api here
  const handleStartAssessment = async (status: string) => {
    if (status === "Initiated") {
      const expData = {
        applicantId: detailsData?.applicantId,
        assessmentId: detailsData?.assessmentId,
        skillExperienceData: updateskills,
      };
      await updateApplicantAssessmentSkillExperince(expData);
      navigate(`/${currentLanguage}/startassessment`, {
        state: {
          assessmentDetailsData: detailsData,
          instructions: detailsData?.instruction ?? "" + combinedInstructions,
        },
      });
    } else {
      const extractedData = updateskills.map(({ skillId, experience }) => ({
        skillId,
        experience,
      }));
      const startAssessmentRes = await startApplicantAssessment(
        detailsData?.applicantId,
        detailsData?.assessmentId,
        capturedImageData,
        extractedData,
      );
      if (startAssessmentRes?.data?.status === 200) {
        sessionStorage.clear();
        showToast(startAssessmentRes?.data?.customMessage, "success");
        navigate(`/${currentLanguage}/startassessment`, {
          state: {
            assessmentDetailsData: detailsData,
            instructions: detailsData?.instruction ?? "" + combinedInstructions,
          },
        });
      } else {
        showToast(startAssessmentRes?.data?.customMessage, "error");
      }
    }
  };

  const handleDeviceChange = ({
    cameraCallBack,
    microphoneCallBack,
    internetCallBack,
  }: any) => {
    if (isCheckCamerapermission) {
      if (cameraPermissions.camera !== cameraCallBack) {
        setCameraPermissions({
          camera: cameraCallBack,
        });
      }
    }

    if (isCheckMicpermission) {
      if (micPermissions.mic !== microphoneCallBack) {
        setMicPermissions({
          mic: microphoneCallBack,
        });
      }
    }
    if (isCheckInternetpermission) {
      if (internetSpeed !== internetCallBack) {
        retestInternetConnect();
      }
    }
  };

  // function to get Assessment Details With Experience
  async function getAssessmentDetailsWithExperience() {
    const resAssessDetails =
      await getApplicantAssessmentStatusAndDetailsWithExperience(
        applicantIdInParams ?? "",
        assessmentIdParam ?? "",
      );

    if (resAssessDetails?.status === 200) {
      setDetailsData(resAssessDetails?.data);
    } else {
      showToast(resAssessDetails?.customMessage, "error");
    }
  }
  return (
    <>
      <div
        className={`${
          loggedInParams == "true" ? "" : isUserSubmit ? "userActive" : ""
        }`}
      >
        <div className="assessmentInstructSection">
          <SubmitAssessmentHeader>
            <ButtonWrapper text="" onClick={() => {}}>
              <div className="assessmentInstrunctioPopup">
                <button className="instructionBtn" onClick={() => handleShow()}>
                  {t("GENERAL.INSTRUCTIONS")}
                  <InstructionModal
                    show={show}
                    handleClose={handleClose}
                    handleShow={handleShow}
                    instruction={
                      detailsData?.instructions + combinedInstructions
                    }
                  />
                  <SubmitAssessmentCalender
                    classname="calender"
                    width="17"
                    height="20"
                  />
                </button>
              </div>
            </ButtonWrapper>
          </SubmitAssessmentHeader>
          <div className="container-fluid attempCount">
            <span>
              {t("SUBMIT_ASSESSMENT.TOTAL_ATTEMPTS")} :-{" "}
              {detailsData?.totalAttempts}/5
            </span>
          </div>
          <div className="container-fluid mt-1">
            <div className="d-flex gap-2">
              <h5 className="assessmenttitle">{detailsData?.title ?? "-"}</h5>
              {/* <p>
                {detailsData?.applicationStatus && (
                  <>({detailsData?.applicationStatus})</>
                )}
              </p> */}
            </div>
            {detailsData?.description && (
              <p
                dangerouslySetInnerHTML={{
                  __html: `${detailsData?.description}`,
                }}
              />
            )}
            <div className="details">
              <div className="time">
                <h5>{t("SUBMIT_ASSESSMENT.APPROXIMATE_TIME")}</h5>
                <p>
                  {detailsData?.totalTime} {t("GENERAL.MINS")}
                </p>
              </div>
              <div className="question">
                <h5>{t("GENERAL.TOTAL_QUESTION")}</h5>
                <p>{detailsData?.totalQuestions}</p>
              </div>
            </div>
            <div className="row mt-3">
              {updateskills?.length > 0 && (
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="skillsListedPara gap-2 mb-4">
                    <h4>{t("GENERAL.RATE_YOUR_SKILLS")}</h4>
                  </div>
                  <div className="langExp">
                    <p>{t("GENERAL.SKILL")}</p>
                    <p>{t("SUBMIT_ASSESSMENT.EXPERIENCE_YEAR")}</p>
                  </div>
                  <div className="skills">
                    {updateskills?.length > 0 ? (
                      (updateskills ?? [])?.map((item: any, ind: number) => {
                        return (
                          <div className="skill-set" key={item?.skillId}>
                            <div className="icontext">
                              <span className="icon">
                                <img
                                  className="iconImage"
                                  src={item?.skillIcon}
                                  alt="skillIcon"
                                />
                              </span>
                              {item?.skillName}
                            </div>
                            <div className="rangeSelector">
                              <RangeSelector
                                id={`${item?.skillId + ind}`}
                                label={""}
                                minLabel={0}
                                maxLabel={20}
                                min={0}
                                max={20}
                                step={1}
                                value={item?.experience}
                                setValue={(value) => {
                                  handleSkillSet(item?.skillId, value);
                                }}
                                // disabled={disableSkillsRange}
                              />
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div>{t("GENERAL.NOT_AVAILABLE")}</div>
                    )}
                  </div>
                </div>
              )}
              <SystemCompatibility
                micPermissions={micPermissions}
                checkMicPermissions={checkMicPermissions}
                checkCameraPermission={checkCameraPermission}
                checkInternetSpeed={checkInternetSpeed}
                cameraPermissions={cameraPermissions}
                internetSpeed={internetSpeed}
                internetSpeedIsLoading={internetSpeedIsLoading}
                retestInternetConnect={retestInternetConnect}
                internetSpeedCount={internetSpeedCount}
                capturedPic={capturedPic}
                captureImageCallBack={captureImageCallBack}
                videoRef={videoRef}
                headerVisible={true}
                className="col-lg-6 col-md-6 col-sm-12"
              />
            </div>
          </div>
          {!(loggedInParams === "true") && isUserSubmit && (
            <User
              setShow={setShow}
              setIsUserSubmit={setIsUserSubmit}
              userEmail={userEmail}
            />
          )}
          {show && (
            <InstructionModal
              show={show}
              handleClose={handleClose}
              handleShow={handleShow}
              instruction={detailsData?.instructions + combinedInstructions}
            />
          )}
          {showCameraModal && (
            <CustomModal
              instructionHeadTitle={t("GENERAL.CAPTURE_IMAGE")}
              show={showCameraModal}
              handleClose={() => {
                setShowCameraModal(false);
                closeCameraCaptureModal();
              }}
              handleShow={() => setShowCameraModal(true)}
              isCloseEnable={true}
            >
              <div className="intructionImageCapture">
                <>
                  <div
                    className={`${
                      capturedImageData ? "displayNone" : "text-center"
                    }`}
                  >
                    <video ref={capturedVideoRef} autoPlay muted />
                  </div>
                  <div
                    className={`${
                      !capturedImageData ? "displayNone" : "text-center"
                    }`}
                  >
                    <img src={capturedImageData} alt="Captured" />
                  </div>
                  {capturedImageData ? (
                    <AssessmentButton onClick={() => setCapturedImageData("")}>
                      {t("GENERAL.RETAKE")}
                    </AssessmentButton>
                  ) : (
                    <AssessmentButton onClick={handleCapture}>
                      {t("GENERAL.CAPTURE")}
                    </AssessmentButton>
                  )}
                </>
              </div>
            </CustomModal>
          )}
          <DeviceDetector
            onDeviceChange={handleDeviceChange}
            videoRef={videoRef}
            isCheckCamerapermission={isCheckCamerapermission}
            isCheckMicpermission={isCheckMicpermission}
            isCheckInternetpermission={isCheckInternetpermission}
          />
        </div>
        <div className="container-fluid submitAssessment">
          <AssessmentButton
            onClick={() => {
              const handleFullscreen = () => {
                if (!document.fullscreenElement) {
                  document.documentElement
                    .requestFullscreen()
                    .then(() => {
                      sessionStorage.setItem("isFullscreen", "true");
                    })
                    .catch(() => {
                      alert(t("ERROR.EXIT_FULL_SCREEN_MODE"));
                    });
                }
              };
              if (detailsData?.applicationStatus === "Finished") {
                showToast(t("GENERAL.COMING_SOON"), "warning");
              } else {
                handleStartAssessment(detailsData?.applicationStatus);
                sessionStorage.setItem("isFullscreen", "true");
                handleFullscreen();
              }
            }}
            className={
              isBtnEnable(
                micPermissions,
                cameraPermissions,
                internetSpeed,
                capturedPic,
              )
                ? "submitBtn-disable"
                : "submitBtn-enable"
            }
            disabled={isBtnEnable(
              micPermissions,
              cameraPermissions,
              internetSpeed,
              capturedPic,
            )}
          >
            {detailsData?.applicationStatus === "Initiated"
              ? t("RESUME_ASSESSMENT")
              : detailsData?.applicationStatus === "Finished"
                ? t("GENERAL.RESULT")
                : t("START_ASSESSMENT")}
          </AssessmentButton>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AssessmentDetails;
