import React, { useRef, useEffect, useState } from "react";
import { videoUploader } from "../../../utils/Common/MediaUploader";
interface Recorder {
  SpeechRecognition?: any;
  timer?: number;
  currentQuestionIndex?: number;
  questionId?: number | string;
  setVideoUrl?: any;
  isCodingQuestion?: boolean;
  data: any;
  handleSubmitAnswer?: any;
  setStartQuestionLoader: any;
  startQuestionLoader: boolean;
}
const Recorder: React.FC<Recorder> = ({
  SpeechRecognition,
  timer,
  currentQuestionIndex,
  questionId,
  isCodingQuestion = false,
  data,
  handleSubmitAnswer,
  setStartQuestionLoader,
  startQuestionLoader,
}) => {
  const secondPageVideoRef = useRef<HTMLVideoElement>(null);
  const mediaRecorder = useRef<MediaRecorder | any>(null);
  const [recordingStopped, setRecordingStopped] = useState(false);
  const setupCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: startQuestionLoader ? false : true,
        video: startQuestionLoader ? false : true,
      });

      if (secondPageVideoRef.current) {
        secondPageVideoRef.current.srcObject = stream;
      }

      if (!mediaRecorder.current) {
        mediaRecorder.current = new MediaRecorder(stream);
      }
      if (mediaRecorder.current?.state === "inactive") {
        mediaRecorder.current.start();
        setRecordingStopped(true);
        SpeechRecognition.startListening({ continuous: true });
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  useEffect(() => {
    setupCamera();
    return () => {
      if (
        mediaRecorder.current &&
        mediaRecorder?.current?.state === "recording"
      ) {
        mediaRecorder.current.stop();
      }
    };
  }, [currentQuestionIndex, questionId]);

  useEffect(() => {
    const checkMediaDevices = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: startQuestionLoader ? false : true,
          video: startQuestionLoader ? false : true,
        });

        // Stop the stream to release resources
        stream.getTracks().forEach((track) => track.stop());

        if (timer === 0) {
          setStartQuestionLoader(true);
          await stopRecording();
          stopCamera();
        }
      } catch (error) {
        console.error(error);
      }
    };
    checkMediaDevices();
  }, [timer, recordingStopped]);
  const stopCamera = () => {
    const stream = secondPageVideoRef.current?.srcObject as MediaStream;
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      secondPageVideoRef.current!.srcObject = null;
    }
  };
  const stopRecording = async () => {
    return new Promise<void>((resolve) => {
      const chunks: Blob[] = [];
      mediaRecorder.current.ondataavailable = (event: any) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };
      mediaRecorder.current.onstop = async () => {
        const blob = new Blob(chunks, { type: "video/webm" });
        const imagePathUrl = await videoUploader(
          blob,
          `${data?.mediaPath}${questionId}`,
        );
        handleSubmitAnswer(imagePathUrl);
        resolve();
      };
      mediaRecorder.current?.stop();
      setRecordingStopped(false);
    });
  };

  return (
    <>
      {isCodingQuestion ? (
        <video
          style={{ marginLeft: "28px" }}
          width={250}
          ref={secondPageVideoRef}
          muted
          autoPlay={true}
        />
      ) : (
        <video ref={secondPageVideoRef} muted autoPlay={true} />
      )}
    </>
  );
};

export default Recorder;
