// import { InputField } from "ui-components";
import "./QuestionType.scss";
import { useEffect } from "react";

interface VideoType {
  transcript: string;
  browserSupportsSpeechRecognition: any;
  transcriptEnabled: boolean;
}
const VideoType: React.FC<VideoType> = ({
  transcript,
  browserSupportsSpeechRecognition,
  // transcriptEnabled,
}) => {
  useEffect(() => {}, [transcript]);
  return (
    <>
      {!browserSupportsSpeechRecognition ? (
        "Browser doesn't support speech recognition"
      ) : (
        <div className="videoType">
          <img
            width={50}
            height={50}
            src={require("../../../../utils/Images/Microphone.gif")}
            alt="gif"
          />
          {/* <InputField
            additionalProps={{
              as: "textarea",
              rows: 12,
              style: { resize: "none" },
            }}
            value={transcriptEnabled ? transcript : ""}
            onChange={() => {}}
          /> */}
        </div>
      )}
    </>
  );
};

export default VideoType;
