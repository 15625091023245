import { handleError } from "../../utils/Common/Constant";
import apiClient from "../Connection/Connection";

//  to validate Assessment Applicant
export const validateAssessmentApplicant = async (
  assessmentSlug: string | number | undefined | null,
  applicantId: string | number | undefined | null,
  token: string | undefined | null,
) => {
  try {
    const res = await apiClient.get(
      `assessmentApplicant/validateAssessmentApplicant?assessmentSlug=${assessmentSlug}&applicantId=${applicantId}&token=${token}`,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};
