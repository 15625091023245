import "./FullPageLoader.scss";
import Loader from "./Loader";
const FullPageLoader = () => {
  return (
    <div className="fullPageLoader">
      <Loader />
    </div>
  );
};
export default FullPageLoader;
