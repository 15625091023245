import moment from "moment";
import { getSessionStorageItem } from "../SessionStorageMethod/getSessionStorageItem";
import { showToast } from "ui-components";
import { v4 as uuidv4 } from "uuid";

// ar: Arabic he: Hebrew fa: Persian (Farsi) ur: Urdu
const rtlLanguages = ["ar", "he", "fa", "ur"]; // Add other RTL languages if needed

export const isRtl = (language: string) => rtlLanguages.includes(language);

// check component disability
export const isDisable = (functionBehavior: any) => {
  return functionBehavior === "disable" ? true : false;
};

// check component hidden or not
export const isHide = (functionBehavior: any) => {
  return functionBehavior === "hide" ? false : true;
};

export const initialReviewData = {
  type: getSessionStorageItem("Type") || "",
  titleHeadContent: getSessionStorageItem("titleHeadContent") || "",
};

// tranform answer set
export const transformAnswerSet = (inputData: any) => {
  const updateAssessmentQuestions = [];
  for (let i = 0; i < inputData?.length; i++) {
    if (inputData[i].type === "Essay") {
      const essayData = inputData[i];
      essayData.customQuestion = true;
      essayData.attachment = inputData[i]?.image;
      delete inputData[i]?.image;
      updateAssessmentQuestions.push(essayData);
    } else if (inputData[i]?.type === "Video") {
      const videoData = inputData[i];
      videoData.customQuestion = true;
      videoData.attachment = inputData[i]?.image;
      delete inputData[i]?.image;
      updateAssessmentQuestions.push(videoData);
    } else if (inputData[i]?.type !== "template") {
      const answerOptions = inputData[i]?.answerSet
        .filter((item: any) => item) // Filter items where selected is false
        .map((item: any) => ({
          option: item?.label,
          attachment: item?.image || null,
        })); // Map to the desired format

      const correctOptions = inputData[i]?.answerSet
        .filter((item: any) => item?.selected) // Filter items where selected is true
        .map((item: any) => ({
          options: item?.label,
          attachment: item?.image || null,
        })); // Map to the desired format
      const UpdateObj = {
        question: inputData[i]?.question || null,
        type: inputData[i]?.type || null,
        attachment: inputData[i]?.image || null,
        timeLimit: inputData[i]?.timeLimit || null,
        totalQuestions: inputData[i]?.questionsCount,
        customQuestion: true,
        answerOptions,
        correctOptions,
      };
      updateAssessmentQuestions.push(UpdateObj);
    } else {
      const {
        difficultyLevel,
        indiAssessTime,
        questionsCount,
        totalQuestions,
        skillId,
        skillLevel,
        skillName,
        title,
        type,
      } = inputData[i];
      const finalObj = {
        difficultyLevel,
        indiAssessTime,
        totalQuestions: questionsCount ?? totalQuestions,
        skillId,
        skillLevel,
        skillName,
        title,
        type,
      };
      updateAssessmentQuestions.push(finalObj);
    }
  }
  return updateAssessmentQuestions;
};

// interface Item {
//   skillId: number | string;
//   id?: number | string;
//   questionsCount?: number | undefined;
//   totalQuestions?: number | undefined;
// }

// export function matchArrays<T extends Item>(arr1: T[], arr2: T[]): any {
//   if ((arr1 || []).length !== (arr2 || []).length) {
//     return false;
//   }

//   // Filter out items where both skillId and id are undefined
//   const filteredArr2 = (arr2 || []).filter(
//     (item) =>
//       item.skillId !== undefined ||
//       item.id !== undefined ||
//       item.questionsCount !== undefined ||
//       item.totalQuestions !== undefined,
//   );

//   // Convert filteredArr2 to a map for faster lookup
//   const map2: Map<string | number, T> = new Map(
//     filteredArr2.map((item) => [
//       item.skillId ?? (item.id as string | number),
//       item,
//     ]),
//   );

//   // Check if all IDs from arr1 are present in arr2 and corresponding questionsCount or totalQuestions match
//   return (arr1 || []).every((item) => {
//     const matchedItem = map2.get(item.skillId ?? item.id);
//     if (matchedItem) {
//       return (
//         (item.questionsCount === undefined ||
//           item.questionsCount === matchedItem.questionsCount) &&
//         (item.totalQuestions === undefined ||
//           item.totalQuestions === matchedItem.totalQuestions)
//       );
//     }
//     return false; // If the item is not found in map2
//   });
// }
// Utility function to format the timer value into minutes and seconds
export const formatTime = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

// text to speak function
export function TextToSpeakMessage(message: string, callback: () => void) {
  const speech: any = new SpeechSynthesisUtterance(message);
  speech.voice = window.speechSynthesis.getVoices()[10];
  speechSynthesis.speak(speech);
  // Call the callback function after the message has been spoken
  speech.onend = callback;
}

export const updateProgressBarAndColors = (
  questionSet: any[],
  currentQuestionIndex: number,
  questionData: any,
  assessmentDetailsData: any,
  setProgressBar: (callback: any) => void,
  setSubQuestionColors: (colors: string[]) => void,
  setApiError: (error: boolean) => void,
) => {
  // to calculate progress bar width
  const progressBar = (currentQuestionIndex: number, totalQuestion: number) => {
    const equalWidth = 100 / (totalQuestion - 1);
    const widthPerCal = equalWidth * currentQuestionIndex;
    return widthPerCal - equalWidth;
  };
  if (questionSet?.length > currentQuestionIndex) {
    if (questionData?.data?.currentQuestion !== 1) {
      setProgressBar((prev: any) => {
        const updateData =
          prev.progressbar < 100
            ? {
                progressbar: progressBar(
                  questionData?.data?.currentQuestion,
                  assessmentDetailsData?.totalQuestions,
                ),
                index: prev.index + 1,
              }
            : prev;
        return updateData;
      });
    }
    if (assessmentDetailsData?.applicationStatus === "Initiated") {
      const initialUpdateData = Array.from(
        { length: questionSet.length },
        (_, index) =>
          index < questionData?.data?.currentQuestion ? "#35d4ae" : "#c7c7c7",
      );
      const updatedColors = [...initialUpdateData];
      setSubQuestionColors(updatedColors);
    } else {
      const initialUpdateData = Array.from(
        { length: questionSet.length },
        (_, index) => (index === 0 ? "#35d4ae" : "#c7c7c7"),
      );
      if (questionData?.data?.currentQuestion > 1) {
        const updatedColors = Array.from(
          { length: questionSet.length },
          (_, index) =>
            index < questionData?.data?.currentQuestion
              ? "#35d4ae"
              : initialUpdateData[index],
        );
        setSubQuestionColors(updatedColors);
      } else {
        setSubQuestionColors(initialUpdateData);
      }
    }
    setApiError(false);
  }
};

export function extractLanguage(url: string): string | null {
  const parts = url.split("/");
  if (parts.length < 3) {
    return null; // URL doesn't have enough parts
  }
  return parts[1]; // Return the second part of the URL
}
// start edited assessment data

// transform edited custom question
export const reverseTransformAnswerSet = (updateAssessmentQuestions: any[]) => {
  const inputData = [];

  for (const item of updateAssessmentQuestions) {
    if (item.type === "Essay" || item.type === "Video") {
      inputData.push({
        ...item,
        answerSet: [],
        image: item.attachment || null,
        id: Math.random(),
      });
    } else {
      const answerSet = [];
      for (const option of item.answerOptions) {
        answerSet.push({
          label: option.option,
          image: option.attachment || null,
          selected: false,
          id: Math.random(),
        });
      }
      for (const option of item.correctOptions) {
        answerSet.push({
          label: option.option,
          image: option.attachment || null,
          selected: true,
          id: Math.random(),
        });
      }
      inputData.push({
        id: Math.random(),
        question: item.question,
        type: item.type,
        timeLimit: item.timeLimit,
        answerSet,
        image: item.attachment || null,
      });
    }
  }
  return inputData;
};
// end edited assessment data
// extract path from url
export function extractPathFromUrl(url: string): string | null {
  // eslint-disable-next-line no-useless-escape
  const match = url.match(/\/a\/([^\/?#]+)/);
  return match ? match[0].substring(1) : null;
}

// handle error while calling api
export function handleError(error: any) {
  if (error?.customMessage) {
    showToast(error?.customMessage, "error");
  } else {
    console.log("error", error);
  }
}

// if we have uniqueId then use that otherwise use new
export const foundUniqueId = (id: string | undefined | null) => {
  if (id) {
    return id;
  } else {
    const uniqueId = uuidv4();
    localStorage.setItem("tempUniqueId", uniqueId);
    return uniqueId;
  }
};

// delay debounce function
type CallbackFunction = (...args: any[]) => void;

export const debounceFn = (
  func: CallbackFunction,
  delay: number,
): CallbackFunction => {
  let timeoutId: NodeJS.Timeout | null;

  return (...args: any[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const convertDate = (
  dateTime: string,
  withComma?: boolean,
  withTime?: boolean,
) => {
  const days: { [key: string]: string } = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
    Sun: "Sunday",
  };
  const dateFormat = moment(dateTime).format("llll").split(",");
  const year = dateFormat[2].split(" ")[1];
  const time = dateFormat[2].split(" ")[2] + " " + dateFormat[2].split(" ")[3];
  const date = dateFormat[1].split(" ")[2];
  const month = dateFormat[1].split(" ")[1];
  const day = dateFormat[0];
  if (withComma) {
    return `${date} ${month} ${year}, ${days[day]}`;
  } else if (withTime) {
    return `${date} ${month} ${year}, ${days[day]} ${time}`;
  } else {
    return `${date} ${month} ${year} (${days[day]})`;
  }
};

export const handleKeyPressSearch = (
  e: React.KeyboardEvent<HTMLInputElement>,
) => {
  if (e.key === "Enter") {
    e.preventDefault(); // Prevent default form submission on Enter key
  }
};

interface ISkillData {
  skillName: string;
  skillIcon: string;
  difficultyLevel: string;
  totalQuestions: number;
}

// manage the duplicate skill and mention the difficultyLevel
export function manageDuplicateSkills(data: ISkillData[]): any {
  if (data) {
    // IMPORTANT (give the difficultyLevel in case of duplicate)
    // const duplicateEntries: ISkillData[] = (data || []).filter(
    //   (entry, index) => {
    //     return (
    //       (data || []).findIndex(
    //         (item) => item.skillName === entry.skillName,
    //       ) !== index
    //     );
    //   },
    // );

    // (data || []).forEach((entry) => {
    //   if (
    //     duplicateEntries.some(
    //       (dupEntry) => dupEntry.skillName === entry.skillName,
    //     )
    //   ) {
    //     entry.skillName += ` (${entry.difficultyLevel})`;
    //   }
    // });
    // return data ?? [];
    return (
      data.map((skill) => ({
        ...skill,
        skillName: `${skill.skillName} - ${skill.difficultyLevel}`,
      })) ?? []
    );
  } else {
    return null;
  }
}

// user login User Data
export const loginUserData = {
  email: "",
  password: "",
};

// decode text
export function convertToText(value: string) {
  if (value === undefined || value === null) {
    return "";
  } else {
    return atob(value);
  }
}

// api for to get ip and geolocation address
export const geoLocationapiClient = async () => {
  const res = await fetch("https://ipapi.co/json/");
  const ipData = res?.json();
  return ipData;
};

// get pascal string
export function toPascalCase(str: string) {
  return str
    ?.toLowerCase() // Convert the entire string to lowercase
    .split(" ") // Split the string by spaces
    .filter((word) => word) // Remove any empty strings from the array
    .map((word) => {
      // Capitalize the first letter of each word and concatenate with the rest of the word
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" "); // Join all words together without spaces
}

// get camel case string
export function toCamelCase(str: string) {
  return str
    .split(" ")
    .map((word, index) => {
      const lowerCasedWord = word.toLowerCase();
      return index === 0
        ? lowerCasedWord
        : lowerCasedWord.charAt(0).toUpperCase() + lowerCasedWord.slice(1);
    })
    .join("");
}
