import Listing from "./AssessmentList/Listing/Listing";

const SubmitAssessment = () => {
  return (
    <div>
      <Listing />
    </div>
  );
};

export default SubmitAssessment;
