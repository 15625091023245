const RefreshIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="2.5"
        fill="white"
        stroke="#35D4AE"
      />
      <path
        d="M12.75 5L11.25 6.29704L11.2944 6.33557M11.2944 6.33557L12.75 7.59999M11.2944 6.33557C11.5258 6.31208 11.7613 6.29999 12 6.29999C15.3137 6.29999 18 8.6281 18 11.5C18 13.1421 17.1217 14.6065 15.75 15.5595M11.25 15.4L12.75 16.642L12.7277 16.662M12.7277 16.662C12.4892 16.6871 12.2463 16.6999 12 16.6999C8.68629 16.6999 6 14.3718 6 11.5C6 9.85783 6.8783 8.39347 8.25 7.4405M12.7277 16.662L11.25 18"
        stroke="#35D4AE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RefreshIcon;
