import { useState, useEffect } from "react";

export interface IDeviceDetectorProps {
  onDeviceChange?: any;
  videoRef?: any;
  isCheckCamerapermission: boolean;
  isCheckMicpermission: boolean;
  isCheckInternetpermission: boolean;
}

const DeviceDetector: React.FunctionComponent<IDeviceDetectorProps> = ({
  onDeviceChange,
  videoRef,
  isCheckCamerapermission,
  isCheckMicpermission,
  isCheckInternetpermission,
}) => {
  const [isCameraAvailable, setIsCameraAvailable] = useState<null | boolean>(
    null,
  );
  const [isMicrophoneAvailable, setIsMicrophoneAvailable] = useState<
    null | boolean
  >(null);
  const [isOnline, setIsOnline] = useState<null | boolean>(null);
  useEffect(() => {
    const checkDeviceAvailability = async () => {
      try {
        if (isCheckCamerapermission) {
          let cameraPermission = false;
          try {
            const videoStream = await navigator.mediaDevices.getUserMedia({
              video: true,
            });
            cameraPermission = videoStream.getVideoTracks().length > 0;
            if (cameraPermission) {
              if (videoRef.current) {
                videoRef.current.srcObject = videoStream;
              }
            }
            setIsCameraAvailable(cameraPermission);
          } catch (error) {
            setIsCameraAvailable(error ? false : true);
          }
        }
        if (isCheckMicpermission) {
          try {
            let micPermission = false;
            const audioStream = await navigator.mediaDevices.getUserMedia({
              audio: true,
            });
            micPermission = audioStream.getAudioTracks().length > 0;
            audioStream.getTracks().forEach((track) => track.stop());
            setIsMicrophoneAvailable(micPermission);
          } catch (error) {
            setIsMicrophoneAvailable(error ? false : true);
          }
        }
        if (isCheckInternetpermission) {
          try {
            if (navigator.onLine) {
              setIsOnline(true);
            } else {
              setIsOnline(false);
            }
          } catch (error) {
            setIsOnline(error ? false : true);
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-constant-condition
        if (false) {
          console.warn("Error checking device availability:", error);
        }
      }
    };
    const intervalId = setInterval(checkDeviceAvailability, 700);
    // Check device availability immediately when the component mounts
    checkDeviceAvailability();
    // Clean up by clearing the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [
    isCheckCamerapermission,
    isCheckMicpermission,
    isCheckInternetpermission,
  ]);

  useEffect(() => {
    // Trigger the callback when any device availability changes
    onDeviceChange({
      cameraCallBack: isCameraAvailable,
      microphoneCallBack: isMicrophoneAvailable,
      internetCallBack: isOnline,
    });
  }, [isCameraAvailable, isMicrophoneAvailable, isOnline, onDeviceChange]);

  return null; // This component doesn't render anything directly
};

export default DeviceDetector;
