import { CheckboxRadio } from "ui-components";
import "./QuestionType.scss";

interface SingleType {
  data?: any;
  setSingleTypeValue?: any;
  singleTypeValue?: any;
  startQuestionLoader: boolean;
}
const SingleType: React.FC<SingleType> = ({
  data,
  setSingleTypeValue,
  singleTypeValue,
  startQuestionLoader,
}) => {
  const handleType = (item: any) => {
    setSingleTypeValue(item);
  };
  return (
    <div>
      {data?.answerOptions.map((item: any, ind: number) => (
        <div
          style={{
            pointerEvents: startQuestionLoader ? "none" : "auto",
          }}
          className={`${item?.option ? "d-flex gap-2 common" : "commonType"}`}
          key={ind}
          onClick={() => handleType(item)}
        >
          <CheckboxRadio
            name="singleType"
            type="radio"
            checked={singleTypeValue === item}
            additionalProps={{ disabled: startQuestionLoader ? true : false }}
          />
          <div>
            {item?.option && <p>{item?.option}</p>}
            {item?.attachment && (
              <div className="ImageAttParent mb-2">
                <img
                  className="attachedImageQuestion"
                  src={item?.attachment}
                  alt="Attachment"
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SingleType;
