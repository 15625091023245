import { useTranslation } from "react-i18next";
import CustomModal from "../../../components/CustomModal/CustomModal";
import "./InstructionModal.scss";

export interface IinstructionModal {
  instruction: any;
  show: boolean;
  handleClose: any;
  handleShow: any;
}
const InstructionModal: React.FunctionComponent<IinstructionModal> = ({
  instruction,
  show,
  handleClose,
  handleShow,
}) => {
  const { t } = useTranslation();
  return (
    <CustomModal
      show={show}
      handleClose={handleClose}
      handleShow={handleShow}
      isCloseEnable={true}
      instructionHeadTitle={t("GENERAL.INSTRUCTIONS")}
    >
      <div className="instructionModalContent">
        <p
          className="assessmentDetailsInstruction"
          dangerouslySetInnerHTML={{
            __html: `${instruction}`,
          }}
        />
      </div>
    </CustomModal>
  );
};

export default InstructionModal;
