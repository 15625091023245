const CircleCrossTick = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#E41B1B" fillOpacity="0.1" />
      <circle cx="16" cy="16" r="11" fill="#E41B1B" />
      <path
        d="M17.2737 16L19.7318 13.5556C20.0894 13.2 20.0894 12.6667 19.7318 12.3111C19.5978 12.1333 19.3743 12 19.1508 12C18.9274 12 18.7039 12.0889 18.5251 12.2667L16.0223 14.7556L13.5642 12.3111C13.2067 11.9556 12.6257 11.9556 12.3128 12.3111C12.1341 12.4444 12 12.6667 12 12.9333C12 13.2 12.0894 13.3778 12.2682 13.5556L14.7263 16L12.2682 18.4444C12.1341 18.6222 12 18.8444 12 19.1111C12 19.3333 12.0894 19.5556 12.2682 19.7333C12.4469 19.9111 12.6704 20 12.8939 20C13.1173 20 13.3408 19.9111 13.5196 19.7333L15.9777 17.2889L18.4358 19.7333C18.7933 20.0889 19.3743 20.0889 19.6872 19.7333C20.0447 19.3778 20.0447 18.8 19.6872 18.4889L17.2737 16Z"
        fill="white"
      />
    </svg>
  );
};

export default CircleCrossTick;
